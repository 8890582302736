import { i18n } from "@/modules/App/components/i18n/i18n.client"
import { BookingComp } from "@/shared/components/Booking/booking"
import { Pet } from "@/shared/types/global-store"
import { IS_DEFFERED_SUBSCRIPTION_ACTIVE } from "@/shared/utils/flag-features"
import {
  differenceInMonths,
  differenceInYears,
  format,
  formatDuration,
  intervalToDuration,
  isAfter,
  parse,
} from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"
import deLocale from "date-fns/locale/de"
import enLocale from "date-fns/locale/en-GB"
import { Trans } from "react-i18next"

/** @returns label value array of month */
export function generateMonthsOptions(local = "en-EN") {
  return Array.from({ length: 12 }, (_, index) => {
    return {
      label: new Date(0, index).toLocaleString(local, { month: "long" }),
      value: String(index + 1),
    }
  })
}

/** @returns label value array of years starting form 2003 to now */
export function generateYearsOptions() {
  const currentYear = new Date().getFullYear()
  // TODO: change this, I add it ramdomly
  const startYear = 2003

  const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) =>
    (startYear + index).toString()
  )

  return years
    .map((year) => ({
      label: year,
      value: year,
    }))
    .reverse()
}

/** @returns  pet birthday date and formatted date */
export function getPetBirthday(birthMonth: string, birthYear: string) {
  const timeZone = "UTC"

  const birthDate = zonedTimeToUtc(
    parse(`${birthMonth}-01-${birthYear}`, "M-d-yyyy", new Date()),
    timeZone
  )

  /* 
   "Invalid Date" is a date returned when the parsing fails
   due to an non-existing date (ex: 30 FEBRUARY, 31 APRIL, etc...)
  */
  if (birthDate.toString() === "Invalid Date") {
    return { birthDate: undefined, formattedBirthDate: undefined }
  }

  const formattedBirthDate = format(birthDate, "yyyy-MM-dd")
  return { birthDate, formattedBirthDate }
}

/** @returns age in years */
export const calculateAgeInYears = (birthDate: Date, currentDate: Date): number => {
  return differenceInYears(currentDate, birthDate)
}

/** @returns age in months */
export const calculateAgeInMonths = (birthDate: Date, currentDate: Date): number => {
  return differenceInMonths(currentDate, birthDate)
}

/** @return true if pet is too young */
export const isPetTooYoung = (
  birthDate: Date | undefined,
  currentDate: Date | undefined
): boolean | undefined => {
  if (!birthDate || !currentDate) {
    return undefined
  }
  const ageInYears = calculateAgeInYears(birthDate, currentDate)
  const ageInMonths = calculateAgeInMonths(birthDate, currentDate)

  return ageInYears === 0 && ageInMonths < 2
}

/** @returns true if pet is too old */
export const isPetTooOld = (
  birthDate: Date | undefined,
  currentDate: Date | undefined
): boolean | undefined => {
  if (!birthDate || !currentDate) {
    return undefined
  }
  const ageInYears = calculateAgeInYears(birthDate, currentDate)

  return ageInYears > 8
}

/** @returns true if birthdate is after now */
export const checkIsAfterPetBirthDate = (birthMonth: string, birthYear: string): boolean => {
  if (!birthMonth || !birthYear) {
    return false
  }
  const { birthDate } = getPetBirthday(birthMonth, birthYear)
  const currentDate = new Date()
  return isAfter(birthDate!, currentDate)
}

/** @returns true if it's a valid date */
export const matchIsValidDate = (
  birthMonth: string | undefined,
  birthYear: string | undefined
): "idle" | "valid" | "invalid" | "valid-with-pet-young" => {
  if (!birthMonth || !birthYear) {
    return "idle"
  }
  const currentDate = new Date()
  const { birthDate } = getPetBirthday(birthMonth, birthYear)

  if (checkIsAfterPetBirthDate(birthMonth, birthYear)) {
    return "invalid"
  }

  if (isPetTooOld(birthDate, currentDate)) {
    return "invalid"
  }

  if (isPetTooYoung(birthDate, currentDate)) {
    return IS_DEFFERED_SUBSCRIPTION_ACTIVE ? "valid-with-pet-young" : "invalid"
  }

  return "valid"
}

export function checkForAlert(validPets: Pet[]): boolean {
  if (!validPets?.length) return false

  const today = new Date()

  const agesInMonths = validPets.map((pet) => {
    if (!pet?.birthday) {
      return 0
    }

    return calculateAgeInMonths(new Date(pet.birthday), today)
  })

  const oldestPetAgeInMonths = Math.max(...agesInMonths)

  const hasYoungPet = validPets.some((pet) => {
    if (!pet?.birthday) return false
    return isPetTooYoung(new Date(pet.birthday), today)
  })

  return hasYoungPet && oldestPetAgeInMonths >= 105
}

export function checkForAlertPetBirthdate(validPets: Pet[]): boolean {
  if (!validPets?.length) return false

  const today = new Date()
  let hasYoung = false
  let hasOld = false

  validPets.forEach((pet) => {
    if (pet?.birthday) {
      const birthDate = new Date(pet.birthday)
      const ageInMonths =
        (today.getFullYear() - birthDate.getFullYear()) * 12 +
        today.getMonth() -
        birthDate.getMonth()

      if (ageInMonths < 2) {
        hasYoung = true
      }
      if (ageInMonths >= 105) {
        hasOld = true
      }
    }
  })

  return hasYoung && hasOld
}

export function getBirthdateInfoCardSubtitle(
  birthMonth: string,
  birthYear: string,
  petSex: Pet["sex"],
  petName: Pet["name"]
) {
  const { birthDate } = getPetBirthday(birthMonth, birthYear)
  const currentDate = new Date()

  if (checkIsAfterPetBirthDate(birthMonth, birthYear)) {
    return i18n.t(`pet-birthdate-errors.future-birthdate`, {
      ns: "pet-birthdate-page",
    })
  }

  if (isPetTooYoung(birthDate, currentDate)) {
    return (
      <Trans
        i18n={i18n}
        ns="pet-birthdate-page"
        i18nKey={
          IS_DEFFERED_SUBSCRIPTION_ACTIVE
            ? "pet-birthdate-errors.pet-is-too-young"
            : "pet-birthdate-errors.pet-is-too-young-error"
        }
        values={{
          pet_name: petName,
          eventual_start_date: birthDate
            ? format(new Date(birthDate.setMonth(birthDate.getMonth() + 2)), "dd/MM/yyyy")
            : undefined,
        }}
        components={{
          drawer: <BookingComp />,
        }}
      />
    )
  }
}

/** @returns text about birthdate
 * january is 1
 */
// export function getBirthdateInfoCardSubtitle(
//   birthMonth: string,
//   birthYear: string,
//   petSex: Pet["sex"]
// ) {
//   const { birthDate } = getPetBirthday(birthMonth, birthYear)
//   const currentDate = new Date()

//   if (checkIsAfterPetBirthDate(birthMonth, birthYear)) {
//     return i18n.t(`pet-birthdate-errors.future-birthdate`, {
//       ns: "pet-birthdate-page",
//     })
//   }

//   if (isPetTooYoung(birthDate, currentDate)) {
//     return i18n.t(`pet-birthdate-errors.pet-is-too-young`, {
//       ns: "pet-birthdate-page",
//     })
//   }

//   if (isPetTooOld(birthDate, currentDate)) {
//     return i18n.t(`pet-birthdate-errors.pet-is-too-old`, {
//       ns: "pet-birthdate-page",
//     })
//   }

//   return i18n.t(`pet-birthdate-info-card-subtitle-${petSex.toLowerCase()}`, {
//     ns: "pet-birthdate-page",
//   })
// }

/** @returns parsed Date from string "yyyy-MM-dd" */
export function parsePetBirthday(date: string): Date {
  const parsedDate = parse(date, "yyyy-MM-dd", new Date())
  parsedDate.setHours(0, 0, 0, 0)
  parsedDate.setDate(1)
  return parsedDate
}

/** @returns formatted year and month string from month and year params */
export function getYearAndMonth(birthMonth: string, birthYear: string): string {
  const currentDate = new Date()
  currentDate.setDate(1)

  const birthDate = parse(`${birthMonth}-01-${birthYear}`, "M-d-yyyy", currentDate)

  return formatDuration(
    intervalToDuration({
      start: birthDate,
      end: currentDate,
    }),
    {
      format: ["years", "months"],
      locale: i18n.language === "de" ? deLocale : enLocale,
      delimiter: i18n.language === "de" ? " und " : " and ",
    }
  )
}

/** @returns i18n labeled text  */
export function getYearAndMonthLabel(birthMonth: string, birthYear: string): string {
  const formattedYearAndMonthLabel = i18n.t(`pet-birthdate-info-card-title`, {
    ns: "pet-birthdate-page",
    age: getYearAndMonth(birthMonth, birthYear),
  })

  return formattedYearAndMonthLabel
}
